import { useAppSelector, useAppDispatch } from '@/lib/redux/hooks'
import {
	updateNodeRect,
	updateNodeEditorStyle,
	updateNode,
} from '@/modules/content/contentSlice'
import { AlignNodePanel } from './AlignNodePanel'
import BackgroundPanel from './BackgroundPanel'
import BorderPanel from './BorderPanel'
import DimensionPanel from './DimensionPanel'
import CornerPanel from './CornerPanel'
import TextStylePanel from './TextStylePanel'
import { useHotkeys } from 'react-hotkeys-hook'
import { useGetNodePosition } from '@/modules/canvas/useGetNodePosition'
import PropsPanel from './PropsPanel'

export default function TreePanel() {
	const showInterface = useAppSelector((s) => s.preferences.showInterface)
	const selectedNodeId = useAppSelector((s) => s.canvas.selectedNodeId)
	const selectedNode = useAppSelector(
		(s) => selectedNodeId && s.content.nodes[selectedNodeId]
	)

	return (
		showInterface && (
			<div className='w-60 z-40 bg-white border-l border-zinc-200 h-full shrink-0'>
				<h3 className='text-sm font-medium px-4 py-3 border-b border-zinc-200'>
					Properties
				</h3>
				{selectedNode &&
					(selectedNode.type === 'frame' ? (
						<FrameNodePanelStack node={selectedNode} />
					) : selectedNode.type === 'text' ? (
						<TextNodePanelStack node={selectedNode} />
					) : selectedNode.type === 'component' ? (
						<ComponentNodePanelStack node={selectedNode} />
					) : null)}
			</div>
		)
	)
}

function ComponentNodePanelStack(props: { node: ComponentCanvasNode }) {
	const dispatch = useAppDispatch()
	const position = useGetNodePosition(props.node.id)

	return (
		<div className='divide-y divide-zinc-200'>
			{position && (
				<DimensionPanel
					position={{
						top: position.top,
						left: position.left,
						width: position.width,
						height: position.height,
					}}
					onChangePosition={() => {}}
					canUpdatePosition={false}
					canUpdateSize={false}
				/>
			)}
			<PropsPanel
				node={props.node}
				onChangeProp={(key, value) => {
					dispatch(
						updateNode({
							...props.node,
							props: { ...props.node.props, [key]: value },
						})
					)
				}}
			/>
		</div>
	)
}

function FrameNodePanelStack(props: { node: FrameCanvasNode }) {
	const dispatch = useAppDispatch()

	return (
		<div className='divide-y divide-zinc-200'>
			<AlignNodePanel nodeId={props.node.id} />
			<DimensionPanel
				position={{
					top: props.node.position.top,
					left: props.node.position.left,
					width: props.node.position.width,
					height: props.node.position.height,
				}}
				onChangePosition={(position) => {
					dispatch(
						updateNodeRect({
							nodeId: props.node.id,
							position,
						})
					)
				}}
				canUpdatePosition={props.node.type === 'frame' && !!props.node.parentId}
				canUpdateSize={props.node.type === 'frame'}
			/>
			<CornerPanel
				cornerRadius={props.node.style.cornerRadius || 0}
				onChangeCornerRadius={(cornerRadius) => {
					dispatch(
						updateNodeEditorStyle({
							nodeId: props.node.id,
							style: { cornerRadius },
						})
					)
				}}
			/>
			<BackgroundPanel node={props.node} />
			<BorderPanel node={props.node} />
		</div>
	)
}

function TextNodePanelStack(props: { node: TextCanvasNode }) {
	const dispatch = useAppDispatch()

	useHotkeys('mod+shift+.', () => {
		dispatch(
			updateNode({
				...props.node,
				style: {
					...props.node.style,
					fontSize: props.node.style.fontSize + 1,
				},
			})
		)
	})

	return (
		<div className='divide-y divide-zinc-200'>
			<AlignNodePanel nodeId={props.node.id} />
			<DimensionPanel
				position={{
					top: props.node.position.top,
					left: props.node.position.left,
					width: props.node.position.width,
					height: props.node.position.height,
				}}
				onChangePosition={(position) => {
					dispatch(
						updateNodeRect({
							nodeId: props.node.id,
							position,
						})
					)
				}}
				canUpdatePosition={props.node.type === 'text' && !!props.node.parentId}
				canUpdateSize={props.node.type === 'text'}
			/>
			<TextStylePanel
				style={props.node.style}
				onUpdateStyle={(style) => {
					dispatch(
						updateNode({
							...props.node,
							style,
						})
					)
				}}
			/>
		</div>
	)
}
