import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks'
import { setDragAction, setSelectedNode } from './canvasSlice'
import { createNode, createNodeId } from '@/modules/content/contentSlice'
import { CANVAS_ID, CANVAS_ORIGIN } from '.'

const DEFAULT_FRAME_PROPS: Pick<
	FrameCanvasNode,
	'type' | 'style' | 'childrenIds'
> = {
	type: 'frame',
	style: {
		clipContent: true,
		backgroundColor: { type: 'hex', value: '#ccc' },
	},
	childrenIds: [],
}

const DEFAULT_TEXTNODE_PROPS: Pick<
	TextCanvasNode,
	'type' | 'style' | 'content'
> = {
	type: 'text',
	style: {
		fontSize: 16,
		fontFamily: 'system-ui',
		fontWeight: 'normal',
		color: { type: 'hex', value: '#000000' },
		lineHeight: { type: '*', value: 1 },
	},
	content: '',
}

export default function useCreateChildNode(
	ref: React.RefObject<HTMLDivElement>,
	nodeId: NodeId | typeof CANVAS_ID
) {
	const dispatch = useAppDispatch()
	const dragAction = useAppSelector((s) => s.canvas.dragAction)
	const { activeTool } = useAppSelector((s) => s.canvas)
	const canCreateChildNode = activeTool === 'rectangle' || activeTool === 'text'
	const isCanvas = nodeId === CANVAS_ID

	useEffect(() => {
		const currentRef = ref.current
		if (!currentRef || !canCreateChildNode) return

		const handleMouseDown = (e: MouseEvent) => {
			e.stopPropagation()
			const newNodeId = createNodeId()
			// create new node
			const initialPosition = {
				top: e.offsetY - (isCanvas ? CANVAS_ORIGIN : 0),
				left: e.offsetX - (isCanvas ? CANVAS_ORIGIN : 0),
				width: 0,
				height: 0,
			}

			dispatch(
				createNode({
					id: newNodeId,
					name: 'new node',
					position: initialPosition,
					parentId: isCanvas ? CANVAS_ID : nodeId,
					...(activeTool === 'rectangle'
						? {
								...DEFAULT_FRAME_PROPS,
								style: {
									...DEFAULT_FRAME_PROPS.style,
									backgroundColor: {
										type: 'hex',
										value: isCanvas ? '#fff' : '#ccc',
									},
								},
						  }
						: DEFAULT_TEXTNODE_PROPS),
				})
			)

			dispatch(
				setSelectedNode({
					id: newNodeId,
					isDeepEditingMode: activeTool === 'text',
				})
			)
			// initialize drag state
			dispatch(
				setDragAction({
					type: 'resize',
					initiated: false,
					data: {
						direction: ['right', 'down'],
						anchorCoords: { x: e.clientX, y: e.clientY },
						initDims: initialPosition,
						nodeId: newNodeId,
					},
				})
			)
		}

		currentRef.addEventListener('mousedown', handleMouseDown)

		return () => {
			currentRef.removeEventListener('mousedown', handleMouseDown)
		}
	}, [dispatch, isCanvas, activeTool, nodeId, ref, canCreateChildNode])

	const isDragging = dragAction?.type === 'resize'
	return { isDragging }
}
