import EditorInput from '@/components/EditorInput'
import { PanelContainer, PanelHeader } from './ui/PanelUI'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from './ui/EditorSelect'
import { Label } from '@/components/ui/label'
import lib from '@/modules/content/componentLib'

export default function PropsPanel(props: {
	node: ComponentCanvasNode
	onChangeProp: (key: string, value: string) => void
}) {
	const componentPropsMap = lib[props.node.componentKey].props

	return (
		<PanelContainer>
			<PanelHeader title='Component Props' />
			<div className='grid grid-cols-5 gap-x-2 gap-y-1'>
				{Object.entries(props.node.props).map(([key, value]) => {
					const propInfo = componentPropsMap[key]
					return (
						<div
							className='grid col-span-5 grid-cols-subgrid items-center px-2'
							key={key}
						>
							<Label className='col-span-2'>{key}</Label>
							<div className='col-span-3'>
								{propInfo.type === 'string' ? (
									propInfo.enum ? (
										<Select
											value={value}
											onValueChange={(value) => {
												props.onChangeProp(key, value)
											}}
										>
											<SelectTrigger className=''>
												<SelectValue placeholder='Select' />
											</SelectTrigger>
											<SelectContent>
												{propInfo.enum.map((item) => (
													<SelectItem value={item} key={item}>
														{item}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									) : (
										<EditorInput.InputContainer>
											<EditorInput.TextInput
												value={value}
												onChange={(e) => {
													props.onChangeProp(key, e.target.value)
												}}
											/>
										</EditorInput.InputContainer>
									)
								) : null}
							</div>
						</div>
					)
				})}
			</div>
		</PanelContainer>
	)
}
