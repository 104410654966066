import { createAppAsyncThunk } from '@/lib/redux/hooks'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { CANVAS_ID } from '../canvas'

type ContentSlice = {
	rootNodes: NodeId[]
	nodes: {
		[nodeId: string]: CanvasNode
	}
}

const initialState: ContentSlice = {
	rootNodes: [],
	nodes: {},
}

export const contentSlice = createSlice({
	name: 'content',
	initialState,
	reducers: {
		deleteAllNodes: () => initialState,
		createNode: (state, action: PayloadAction<CanvasNode>) => {
			const node = action.payload
			const name = `node_${Object.keys(state.nodes).length + 1}`
			node.name = name
			state.nodes[node.id] = node

			if (node.parentId === CANVAS_ID) {
				state.rootNodes.unshift(node.id)
			} else {
				const parentNode = state.nodes[node.parentId]
				if (parentNode.type !== 'frame') {
					throw Error('parent node is not a frame')
				}
				parentNode.childrenIds.unshift(node.id)
			}
		},
		insertComponentNode: (
			state,
			action: PayloadAction<{
				componentKey: string
				parentId: CanvasNode['parentId']
			}>
		) => {
			const { parentId, componentKey } = action.payload
			const node: ComponentCanvasNode = {
				type: 'component',
				id: createNodeId(),
				parentId,
				name: '',
				componentKey,
				props: {
					children: 'Sample Button',
					variant: 'destructive',
					className: 'w-full',
				},
			}
			state.nodes[node.id] = node
			if (state.nodes[parentId].type === 'frame') {
				state.nodes[parentId].childrenIds.push(node.id)
			}
		},
		updateNodeEditorStyle: (
			state,
			action: {
				payload: {
					nodeId: NodeId
					style: Partial<FrameCanvasNode['style']>
				}
			}
		) => {
			const { nodeId, style } = action.payload
			if (!('style' in state.nodes[nodeId]))
				throw Error('Node style cannot be updated')

			state.nodes[nodeId].style = {
				...state.nodes[nodeId].style,
				...style,
			}
		},
		updateNode: (state, action: PayloadAction<CanvasNode>) => {
			const node = action.payload
			state.nodes[node.id] = node
		},
		removeNodeEditorStyle: (
			state,
			action: {
				payload: {
					nodeId: NodeId
					editorStyleProp: keyof FrameCanvasNode['style']
				}
			}
		) => {
			const { nodeId, editorStyleProp } = action.payload
			if (state.nodes[nodeId].type !== 'frame')
				throw Error('node is not of type Frame')
			delete state.nodes[nodeId].style[editorStyleProp]
		},
		updateNodeRect: (
			state,
			action: {
				payload: {
					nodeId: NodeId
					position: Partial<ElementDims>
					/** specify parentId to move node to a new parent */
					parentId?: CanvasNode['parentId']
				}
			}
		) => {
			const { nodeId, position: newPosition, parentId } = action.payload
			const node = state.nodes[nodeId]
			const previousParentNode = state.nodes[node.parentId]

			if (
				!!parentId &&
				// drop target is not current parent
				parentId !== node.parentId &&
				// drop target is not itself
				parentId !== node.id
			) {
				// remove node from parent
				if (node.parentId === CANVAS_ID) {
					// remove node from canvas
					state.rootNodes = state.rootNodes.filter((id) => id !== node.id)
				} else {
					if (previousParentNode?.type !== 'frame')
						throw Error('node is not of type Frame')
					previousParentNode.childrenIds =
						previousParentNode.childrenIds.filter((id) => id !== node.id)
				}
				node.parentId = parentId

				// add it to the new drop target
				if (parentId === CANVAS_ID) {
					state.rootNodes.unshift(node.id)
				} else {
					const newParentNode = state.nodes[parentId]
					if (newParentNode.type === 'frame') {
						newParentNode.childrenIds.unshift(node.id)
					} else throw Error('new parent node is not found or wrong type')
				}
			}

			if ('position' in node) {
				if (typeof newPosition.left === 'number')
					node.position.left = newPosition.left
				if (typeof newPosition.top === 'number')
					node.position.top = newPosition.top
				if (typeof newPosition.width === 'number')
					node.position.width = newPosition.width
				if (typeof newPosition.height === 'number')
					node.position.height = newPosition.height
			}
		},
		deleteNode: (state, action: { payload: { nodeId: NodeId } }) => {
			const { nodeId } = action.payload
			const node = state.nodes[nodeId]

			if (node.parentId !== CANVAS_ID) {
				// delete node from parent
				const parent = state.nodes[node.parentId]
				if (parent.type === 'frame') {
					parent.childrenIds = parent.childrenIds.filter((id) => id !== nodeId)
				}
			}
			if (node.type === 'frame') {
				// delete children nodes
				const childrenIds = recursivelyGetChildrenIds(state.nodes, nodeId)
				childrenIds.forEach((id) => {
					delete state.nodes[id]
				})
			}
			// delete node from state
			delete state.nodes[nodeId]
			if (state.rootNodes.includes(nodeId)) {
				state.rootNodes = state.rootNodes.filter((id) => id !== nodeId)
			}
		},
	},
})

function recursivelyGetChildrenIds(
	allNodes: ContentSlice['nodes'],
	nodeId: NodeId
): NodeId[] {
	const node = allNodes[nodeId]

	const childrenIds: NodeId[] = (node as FrameCanvasNode).childrenIds || []
	const nestedChildrenIds = childrenIds.flatMap((id) =>
		recursivelyGetChildrenIds(allNodes, id)
	)
	return [...childrenIds, ...nestedChildrenIds]
}

export const {
	deleteAllNodes,
	insertComponentNode,
	deleteNode,
	createNode,
	updateNode,
	updateNodeRect,
	updateNodeEditorStyle,
	removeNodeEditorStyle,
} = contentSlice.actions

export function createNodeId(): NodeId {
	return `node_${uuidv4()}`
}

export const resetCanvas = createAppAsyncThunk(
	'canvas/reset',
	async (_, { dispatch }) => {
		dispatch(deleteAllNodes())

		function createTestNode(top: number, left: number) {
			const rootNodeId = createNodeId()

			dispatch(
				createNode({
					type: 'frame',
					id: rootNodeId,
					parentId: CANVAS_ID,
					name: 'root',
					position: { left, top, width: 500, height: 500 },
					style: {
						backgroundColor: { type: 'hex', value: '#FFFFFF' },
					},
					childrenIds: [],
				})
			)

			dispatch(
				insertComponentNode({
					parentId: rootNodeId,
					componentKey: 'Button',
				})
			)

			dispatch(
				createNode({
					type: 'frame',
					id: createNodeId(),
					parentId: rootNodeId,
					name: 'Node_',
					position: { left: 100, top: 100, width: 300, height: 300 },
					style: {
						backgroundColor: { type: 'hex', value: '#E6FFC9' },
					},
					childrenIds: [],
				})
			)
		}

		createTestNode(0, 0)
	}
)

export const pressureTest = createAppAsyncThunk(
	'canvas/pressureTest',
	async (_, { dispatch }) => {
		dispatch(deleteAllNodes())

		function createTestNode(top: number, left: number) {
			const rootNodeId = createNodeId()

			dispatch(
				createNode({
					type: 'frame',
					childrenIds: [],
					id: rootNodeId,
					parentId: CANVAS_ID,
					name: 'root',
					position: { left, top, width: 200, height: 200 },
					style: {
						backgroundColor: { type: 'hex', value: '#FFFFFF' },
					},
				})
			)

			const childNodeId = createNodeId()
			dispatch(
				createNode({
					type: 'frame',
					id: childNodeId,
					parentId: rootNodeId,
					name: 'child',
					position: { left: 50, top: 50, width: 100, height: 100 },
					style: {
						backgroundColor: { type: 'hex', value: '#E6FFC9' },
					},
					childrenIds: [],
				})
			)
		}

		for (let x = -1000; x < 3000; x += 250) {
			for (let y = -1000; y < 3000; y += 250) {
				createTestNode(y, x)
			}
		}
	}
)

// // keep for reference
// const initialReactNode: ReactNode = {
// 	type: 'react',
// 	id: 'react_6f371a0c-eaaf-4d7e-9bcf-sjfhsfy3f3f',
// 	name: 'react_comp_1',
// 	componentKey: 'my_button',
// 	props: {},
// 	parentId: CANVAS_ID,
// }
