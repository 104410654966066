import { Button } from '@/components/ui/button'

type ComponentLib = {
	[key: string]: {
		component: React.ComponentType<any>
		props: {
			[key: string]: StringType | BooleanType
		}
	}
}

type _BaseType = {
	required?: boolean
}

type StringType = _BaseType & {
	type: 'string'
	enum?: string[]
}

type BooleanType = _BaseType & {
	type: 'boolean'
}

const lib: ComponentLib = {
	Button: {
		component: Button,
		props: {
			children: {
				type: 'string',
				required: true,
			},
			variant: {
				type: 'string',
				enum: ['default', 'secondary', 'destructive', 'outline', 'ghost'],
			},
			className: {
				type: 'string',
			},
		},
	},
}

export default lib
